<template>
    <div class="container py-5">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>Content</h1>
            </div>
            <div class="col-4">
                <router-link to="admindataset" class="btn btn-primary">Add content</router-link>
            </div>
        </div>
    </div>
</template>
<script>

</script>
